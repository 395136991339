<template>
  <div
    class="flex gap-4 items-center justify-between p-4 md:px-10 top-0 left-0 w-full z-50 leading-5"
    :class="[containerClass]"
  >
    <div
      class="flex gap-4 items-center mx-auto w-full px-5"
      :class="[contentClass]"
    >
      <div class="flex gap-4 items-center w-full">
        <div>
          <icon :icon="ErrorSvg" :size="isMobile ? IconSize.MD : IconSize.LG" />
        </div>
        <slot></slot>
      </div>
      <button
        class="self-start md:self-auto pr-2 md:pr-0"
        @click="emit('close')"
      >
        <icon :icon="DeleteCircleSvg" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import ErrorSvg from '@/assets/icons/error.svg?component';
import DeleteCircleSvg from '@/assets/icons/delete-circle.svg?component';
import { useIsMobile } from '~/composables/use-is-mobile';
import { IconSize, Icon } from '~/components/icon';

type Props = {
  containerClass: string;
  contentClass: string;
};

const props = defineProps<Props>();
const isMobile = useIsMobile();

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>
