<template>
  <banner
    container-class="bg-info md:h-20"
    :content-class="
      authUser.isLoggedIn ? '2xl:pl-70 max-w-[100rem]' : 'max-w-[70rem]'
    "
    @close="emit('close')"
  >
    <div class="max-w-[60rem]">
      <span class="font-semibold">
        We're planning to make updates from {{ getMaintenanceDates() }} and our
        system will be unavailable during that time.
        <div class="md:inline mt-2 md:mt-0">
          If you have any questions please contact
          <a class="font-normal underline" href="mailto:support@exfluential.com"
            >support@exfluential.com</a
          >
        </div>
      </span>
    </div>
  </banner>
</template>
<script lang="ts" setup>
import { format, isSameDay } from 'date-fns';
import { useRuntimeConfig } from '#imports';
import { Banner } from '~/components/banner';
import { useAuthUserStore } from '~/store/auth-user';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const authUser = useAuthUserStore();
const isMobile = useIsMobile();
const config = useRuntimeConfig();
const { maintenanceStartTimestamp, maintenanceEndTimestamp } = config.public;

const getMaintenanceDates = () => {
  if (!maintenanceStartTimestamp || !maintenanceEndTimestamp) {
    return;
  }

  const start = new Date(maintenanceStartTimestamp);
  const end = new Date(maintenanceEndTimestamp);

  if (isSameDay(start, end)) {
    return `${format(start, 'h:mma')} - ${format(end, 'h:mma')} on ${format(
      start,
      'EEEE, MMMM d, yyyy',
    )}`;
  }

  return `${format(start, 'h:mma, MMMM d, yyyy')} to ${format(
    end,
    'h:mma, MMMM d, yyyy',
  )}`;
};
</script>
